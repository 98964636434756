import React, { useState, useEffect } from "react";
import {useSubheader} from "../../_metronic/layout";
import axios from "axios";
import {Link, Switch, Redirect} from "react-router-dom";
import { LoadingOverlay, Loader } from 'react-overlay-loader';

export const PASSENGERS_URL = "stripesuccess";

export function StripeSuccess({ match }) {
  const [accntId, setAccntId] = useState(match.params.accnt);
  const [loading, setLoader] = useState(false);
  const [account, setAccount] = React.useState(null);
  const suhbeader = useSubheader();
  suhbeader.setTitle("Stripe Success");



  async function fetchData() {
    setLoader(true);
    console.log("Account ID:", accntId);
    //let paramsArray = JSON.stringify({"accntId" : accntId});
    axios.post(PASSENGERS_URL, { accntId })
    .then(response => {
          console.log("Account Data: ", response.data);
          setAccount(response.data.data);
          setLoader(false);
        //set state object there...
      })
      .catch(error => {
        setLoader(false);
      });
  }

  useEffect(() => {
      try {
        fetchData();
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
  }, [] );

  return (<>


      <h1>Stripe OnBoarding</h1>
      { !loading ? (
        account?.charges_enabled ?(
          <p>
            Thanks for completing the Stripe OnBoarding Process. Please continue completing your business profile on ReadyNext App.
          </p>
         ) :
        (
          <p>
            Your OnBoarding Process still have some steps missing please continue to Stripe OnBoarding Wizard <br />
            <Link
                to={`/reauth/${accntId}`}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >Continue OnBoarding
          </Link>
          </p>
        )) : <> </>
      }
      <LoadingOverlay style={{ width: "100%", height: 'auto', backgroundColor: '#222222'  }}>
      <Loader loading={loading}/>
      </LoadingOverlay>

      </>);
}
