import React, {useEffect } from "react";
import {useSubheader} from "../../_metronic/layout";

export function About() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("About Us");

  useEffect(() => {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
  });

  return (<>
      <h1>About Us</h1>

      <p>Welcome to the ReadyNext App!</p>


      </>);
}
