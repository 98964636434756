import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {AdminScreen} from "./modules/Admin/pages/admin/AdminScreen";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";
import {Terms} from "./pages/Terms";
import {Privacy} from "./pages/Privacy";
import {About} from "./pages/About";
import {StripeReauth} from "./pages/StripeReauth";
import {StripeSuccess} from "./pages/StripeSuccess";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

const AdminPage = lazy(() =>
  import("./modules/Admin/pages/adminPage")
);


export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    const {isSupport} = useSelector(
        ({auth}) => ({
            isSupport: (auth.user != null && auth.user.utype === 1),
        }),
        shallowEqual
    );

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>

                    <ContentRoute path="/dashboard" component={DashboardPage}/>
                    <ContentRoute path="/builder" component={BuilderPage}/>
                    <ContentRoute path="/admins" component={AdminScreen}/>
                    <ContentRoute path="/my-page" component={MyPage}/>
                    <Route path="/admin" component={AdminPage}/>

                    <Route path="/google-material" component={GoogleMaterialPage}/>
                    <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                    <Route path="/e-commerce" component={ECommercePage}/>
                    <Route path="/reauth/:accnt" component={StripeReauth}/>
                    <Route path="/return/:accnt" component={StripeSuccess}/>
                    <Route path="/terms" component={Terms}/>
                    <Route path="/privacy" component={Privacy}/>
                    <Route path="/about" component={About}/>
                    <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
