import React, {Suspense, lazy, useMemo, useEffect} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {Terms} from "./pages/Terms";
import {Privacy} from "./pages/Privacy";
import {About} from "./pages/About";
import {StripeReauth} from "./pages/StripeReauth";
import {StripeSuccess} from "./pages/StripeSuccess";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);

export default function FrontendPage() {
     useEffect(() => {
       console.log('Frontend page');
     }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    console.log('Frontend routes');
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <Route path="/reauth/:accnt" component={StripeReauth}/>
                <Route path="/return/:accnt" component={StripeSuccess}/>
                <Route path="/terms" component={Terms}/>
                <Route path="/privacy" component={Privacy}/>
                <Route path="/about" component={About}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
