import React, { useState, useEffect } from "react";
import {useSubheader} from "../../_metronic/layout";
import axios from "axios";
import {Link, Switch, Redirect} from "react-router-dom";
import { LoadingOverlay, Loader } from 'react-overlay-loader';

export const PASSENGERS_URL = "stripeauth";

export function StripeReauth({ match }) {
  const [accntId, setAccntId] = useState(match.params.accnt);
  const [loading, setLoader] = useState(false);
  const [message, setMessage] = useState('Loading Stripe Account Details');
  const suhbeader = useSubheader();
  suhbeader.setTitle("Stripe ReAuth");



  async function fetchData() {
    setLoader(true);
    console.log("Account ID:", accntId);
    //let paramsArray = JSON.stringify({"accntId" : accntId});
    axios.post(PASSENGERS_URL, { accntId })
    .then(response => {
          console.log(response.data);
          setMessage(response.data.message);
          if(response.data.data.url){
            window.location = response.data.data.url;
          }

          setLoader(false);
        //set state object there...
      })
      .catch(error => {
        setLoader(false);
      });
  }

  useEffect(() => {
      try {
        fetchData();
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
  }, [] );

  return (<>
    <h1>Stripe OnBoarding</h1>
    { !loading ? (
      message ?(
        <p>
          {message}
        </p>
       ) :
      (
        <p>Unable to load account details</p>
      )) : <> </>
    }
    <LoadingOverlay style={{ width: "100%", height: 'auto', backgroundColor: '#222222'  }}>
    <Loader loading={loading}/>
    </LoadingOverlay>


      </>);
}
