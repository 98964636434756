import React, {useEffect } from "react";
import {useSubheader} from "../../_metronic/layout";

export function Terms() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Terms and Conditions");

  useEffect(() => {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
  });

  return (<>
      <h1>Terms of Use</h1>


	<p>
		Welcome to the ReadyApp owned and operated by Iambrands, Inc. We offer an innovative, proprietary online information, communication and records management system that will be beneficial to you and your health. There are a few rules that our visitors and users must follow when using our Site and Services, so we ask that you read these Terms of Use carefully because your use of the ReadyNext App constitutes your agreement to them.
	</p>
	<p>
		<strong>Your Acceptance of these Terms of Use.</strong>  If you want to access the ReadyApp to use any of the proprietary digital tools we make available (the "Services") you accept these Terms of Use as well as our Privacy Policy and any other policies, guidelines and instructions posted on the Site from time to time (collectively, this "Agreement"). For these purposes the terms "ReadyNext", "we" or "us" refer to Iambrands, Inc.  Expressions beginning with a capital letter usually have a specific meaning that is defined in these terms. You will be agreeing to these terms and the related materials constituting this Agreement when browsing the Site or using our Services. If you do not want to be bound by this Agreement, please do not access the Site or use the Services.
	</p>
	<p><strong>Disclaimer of Professional Advice.</strong>  All content provided on the ReadyNext and through the Services is for informational purposes only. Your reliance on the content of the ReadyNext is solely at your own risk.</p>
	<p><strong>Modifications.</strong> We do not intend to make changes to these Terms of Use very often but we reserve the right to change any of the terms in this Agreement and in any policies, guidelines or instructions governing the Site or Services at any time and in our sole discretion. Any changes will be effective upon posting on the Site and we will use reasonable measures to ensure that changes are prominently posted in a manner designed to afford notice to our users. You should periodically check for the most current terms that apply to your transactions on the Site. We reserve the right to determine the content, appearance, design, functionality and all other aspects of the Site and the Services. We reserve the right to re-design and alter the ReadyNext App and the Services from time to time in order to improve the quality of your experience with us. Your continued use of the ReadyNext App and Services following the posting of any changes means that you agree to them. If you don’t agree to the changes, please do not continue to use the ReadyNext or Services.</p>
	<p><strong>Eligibility.</strong>  Use of the ReadyNext App and Services is limited to parties that lawfully can enter into agreements under applicable law. For example, we do not provide products or services to minors. If you are under 18 you may use the ReadyNext only with the involvement of a parent or guardian.  We do not offer services to or target persons under the age of 13. In compliance with the Children’s Online Privacy Protection Act and other laws concerning the protection of minors and children, we will purge any information we receive from people we believe to be children under 13 from our database and cancel any corresponding accounts and transactions.</p>
	<p><strong>Your Account.</strong> To access our Services you must create an account associated with a valid e-mail address. Accounts may not be sold or transferred. Unless expressly authorized by us you may only create one account per email address. To create an account and register you must provide your name and e-mail address, select a password, and provide other information required by the application form. Valid payment information will be needed when you conduct a transaction on our Site. You are responsible for all activities that occur under your account regardless of whether the activities are undertaken by you, your employees or a third party.  ReadyNext App and its affiliates are not responsible for unauthorized access to your account. You must contact us immediately if you believe an unauthorized party may be using your account or if any of your account information is lost or stolen.</p>
	<p><strong>Electronic Communications.</strong>  When you use the ReadyNext App and our Services you are communicating with us electronically. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. Any information we collect is used and/or managed by us as a service to our users. While we make every effort to verify that the information in connection with the Services we provide is useful to our users, ReadyNext App does not warrant or guaranty its accuracy or timeliness. The App does not sell products or services for purchase by children. If you are under 18, you may use the Site to conduct a transaction with us only with the involvement of a parent or guardian.</p>
	<p><strong>Copyright and Trademark.</strong>  All content included on the ReadyNext App, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations and software, is the property of Iambrands or its content suppliers and is protected by United States and international copyright laws. The compilation of all content on ReadyNext App is the exclusive property of Iambrands and is protected by U.S. and international copyright laws. All software used on this App is the property of Iambrands or its software suppliers and is protected by United States and international copyright laws. All of the trademarks, service marks, brand and trade names appearing on the ReadyNext App including without limitation “Iambrands and various taglines we use are each the proprietary intellectual property of Iambrands or the owners of such marks or names and you may not use, modify, remove or otherwise infringe any of such proprietary intellectual property.  In accordance with the provisions of the Digital Millennium Copyright Act and related laws if you are a copyright owner and believe that any content on the Site infringes your copyright you may inform us by submitting a notice by email to <a href="mailto:support@iambrands.com">support@iambrands.com</a> </p>

      </>);
}
