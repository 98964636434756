import React, {useEffect } from "react";
import {useSubheader} from "../../_metronic/layout";

export function Privacy() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Terms and Conditions");

  useEffect(() => {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
  });

  return (<>

		<h1>Privacy and Data Security Policy </h1>
		<p>
			<strong>Assurance of Your Privacy:</strong><br />
			We know that you care how information about you is used and we appreciate your confidence that we will do so carefully and sensibly. By visiting our app and using our services, you accept the practices described in this policy. This policy explains the information we collect from you, how it is used and safeguarded, and how to contact us if you have any concerns.
		</p>
		<p>
			<strong>Information We Collect:</strong>
			</p><ul>
				<li><strong>Information You Give Us.</strong> When you participate in a transaction with us we ask for certain personal information such as your name, shipping address, email address, and payment account information. We use this information to process your transaction. We also use this information to contact you in case we have any trouble processing your transaction. We reserve the right to consolidate your information so that we can constantly improve our product and service offering to you.</li>
				<li><strong>Email Communications.</strong> To help us make emails more useful and interesting, we may receive a confirmation when you open e-mail from us if your computer supports such capabilities.</li>
			</ul>
		<p></p>
		<p>
			<strong>How Information We Collect Is Used:</strong><br />
			Information about our users is an important part of our business, we guard it well, and we are not in the business of selling it to others. We share user information only as described below and with entities that either are subject to this policy or follow practices at least as protective as those contained in this Policy.
		</p>
		<ul>
			<li><strong>Third-Party Service Providers.</strong> We may employ other companies and individuals to perform functions on our behalf. Examples include things like processing enrollments and payments. Our service providers have access only to personal information needed to perform their functions, and may not use it for other purposes.</li>
			<li><strong>Business Transfers.</strong> As we continue to develop our business, we might sell or buy stores, subsidiaries, or business units. In such transactions, customer information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing policy. Also, in the event that ReadyNext App or substantially all of its assets are acquired, customer information will of course be one of the transferred assets. Again, such information will remain subject to the promises made in any pre-existing policy.</li>
			<li><strong>Protection of ReadyNext and Others.</strong> We may release account and other personal information when we believe release is appropriate to comply with the law, enforce or apply our Terms of Use and other agreements, or protect the rights, property, or safety of ReadyNext and our users. This includes exchanging information with other companies and organizations for fraud protection. However, this does not include selling, renting, sharing, or otherwise disclosing personally identifiable information from customers for commercial purposes in violation of the commitments in this policy.</li>
		</ul>
		<p>
			<strong>Information Security:</strong><br />
			Our data security system is considered among the best in the industry. This security system is for your protection. We use Secure Socket Layer (SSL) encryption when transmitting certain kinds of information such as payment information. Still, it is important for you to protect against unauthorized access to your password, and to your computer as well. For example, be sure to sign off when finished using a shared computer, and to never transmit sensitive information over an unsecured wireless connection. We take the security of your data very seriously and employ all reasonable measures to protect it. Ultimately, no system for transmitting data over the internet is completely secure and we do not guarantee the security of data transmitted between your computer and our servers or otherwise in connection with our website and apps, and you provide your information to us at your risk.
		</p>
		<p>
			<strong>What Information of Yours Can You Access:</strong><br />
			ReadyNext gives you access to a broad range of information about your account and your interaction with us for the purpose of viewing and in certain cases updating that information. You may contact us for these purposes at <a href="mailto:support@iambrands.com">support@iambrands.com</a>.
		</p>
		<p>
			<strong>Data Security:</strong><br />
			We use appropriate software to ensure the wellness and safety of our digital platform, but we do not warrant that our site or our digital correspondence with customers is free from harmful elements. Due to the rapid evolution of computer viruses and other Internet issues, we strongly suggest that you use anti-virus application software when accessing the site.
		</p>

		<p>
			<strong>What Choices Do You Have:</strong><br />
			As mentioned above, you can always choose not to provide information, even though it might be needed to engage in a transaction or to take advantage of features of our application. You can add or update certain information. When you update information, we usually keep a copy of the prior version for our records. If you do not want to receive email or other mail from us, please adjust your preferences in your account. If you do not want to receive Terms of Use updates, Privacy and Data Security Policy updates, and other legal notices from us, those notices will still govern your use of our site, and it is your responsibility to review them for changes.
		</p>
		<p>
			<strong>Information about Changes to this Policy:</strong><br />
			We reserve the right to change any of the terms of this policy at any time. Any changes will be effective upon posting on our website, and we will use reasonable measures to ensure that changes are prominently posted in a manner designed to afford notice to you. You are responsible to periodically check for the most current terms that apply to your transactions with us. Your continued use of our app and any services we offer following the posting of any changes will constitute your acceptance of the changes. If you don’t agree with the changes, please do not continue to use the site or the services.
		</p>

      </>);
}
